import { transformContent } from '@/utils'
import Cta from '../partials/cta'

export default function () {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }

  function getLocation(type) {
    if (type === 'flatfinder') {
      let areas = (get('Project.item.posts') || []).find(
        post => (post.customFieldsConfig[0] || {}).source === 'areas',
      )

      if (areas) {
        return `/${areas.slug}#section-${type}`
      }
    }

    return `#${type}`
  }

  return [
    {
      component: 'Section',
      condition:
        get('media.cover.length') ||
        (get('customFields.embed.url') && get('customFields.embed.show-as-cover')),
      props: {
        width: 'full',
        id: 'section-hero',
        class: 'section-hero subpage-hero-section',
        style: 'padding: 0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            condition: !get('customFields.embed.show-as-cover'),
            data: get('media.cover'),
            props: {
              aspectRatio: '16/9',
              class: 'hero__slider',
            },
          },
          {
            component: 'Embed',
            condition: get('customFields.embed.url') && get('customFields.embed.show-as-cover'),
            props: {
              class: 'subpage-hero-embed',
            },
            data: {
              src: get('customFields.embed.url'),
            },
          },
        ],
      },
    },

    {
      condition: get('content'),
      component: 'Section',
      props: {
        width: 'small',
        id: 'section-info',
        class: 'subpage-info-section',
      },
      blocks: {
        default: [
          {
            component: 'Text',

            data: {
              content: transformContent(get('content')),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('customFields.embed.url') && !get('customFields.embed.show-as-cover'),
      props: {
        width: 'medium',
        class: 'subpage-embed-section',
      },
      blocks: {
        default: [
          {
            component: 'Embed',
            props: {},
            data: {
              src: get('customFields.embed.url'),
            },
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-1') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'],
        },
      }
    }),

    {
      component: 'Section',
      condition: get('customFields.icons.0.icon.url') || get('customFields.icons.0.content'),
      props: {
        width: 'medium',
        class: 'subpage-features-section',
        theme: get('customFields.icons-settings.theme'),
        style: !get('customFields.icons-settings.theme') ? 'padding-top:0;' : '',
      },
      blocks: {
        default: [
          {
            component: 'Features',
            props: {
              columns: parseInt(get('customFields.icons-settings.columns')) || 4,
              iconBackgroundColor: get('customFields.icons-settings.show-icon-background-color')
                ? get('customFields.icons-settings.icon-background-color')
                : 'inherit',
              borderTheme: get('customFields.icons-settings.border-theme'),
              placement: get('customFields.icons-settings.placement'),
              iconBorder: get('customFields.icons-settings.icon-border'),
              boldContent: true,
            },
            data: {
              content: get('customFields.icons'),
            },
            variant: get('customFields.icons-settings.variant') || 'underline',
          },
        ],
      },
    },

    ...(get('customFields.additional-sections-2') || []).map(i => {
      return {
        condition: this.$path('content', i) || this.$path('images.0', i),
        component: 'ImagesTextSection',
        data: {
          content: i,
        },
        props: {
          layout: i.layout,
          width: i.width,
          theme: i.theme || 'none',
          captionPlacement: i['image-caption-placement'],
        },
      }
    }),

    {
      component: 'Section',
      condition: get('customFields.page-settings.display-map'),
      props: {
        width: 'medium',
        class: 'section-map',
      },
      blocks: {
        default: [
          {
            component: 'Map',
            props: {
              aspectRatio: '4/3',
              mapOptions: {
                ...this.mapOptions,
              },
            },
            data: {
              ...get('address', 'Root.item'),
            },
          },
        ],
      },
    },
    Cta(get)
  ]
}
