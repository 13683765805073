<template>
  <div class="post-content" v-html="item"></div>
</template>

<script>
export default {
  props: {
    item: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.post-content {
}
</style>
