<template>
  <div class="post-hero content">
    <Slider class="post-hero__slide" v-if="item.length" :value="item" :display-thumbnail="true" />
  </div>
</template>

<script>
import Slider from '@kvass/media-render/Slider'
export default {
  props: {
    item: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},

  metaInfo() {
    return {
      link: [...this.item.map(item => ({ rel: 'preload', as: 'image', href: item }))],
    }
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.post-hero {
  position: relative;
  width: 100%;
  height: 100%;

  &__slide {
    @include aspect-ratio(16, 7.5);
  }
}
</style>
