<template>
  <Loader :key="$path('title', post)" :value="promises.fetch" class="post-page">
    <Blocks v-if="item.posts" :blocks="config" :customComponents="customComponents" />
  </Loader>
</template>

<script>
import BrowserApiMixin from '@/mixins/browser-api'
import { mapActions, mapState } from 'vuex'
import CustomComponents from '@/components/Custom'
import PostComponents from '@/components/Post'

import Config from '../../config/posts'
import { Blocks } from '@kvass/pagebuilder'

export default {
  mixins: [BrowserApiMixin('post')],
  data() {
    return {
      mapOptions: {},
    }
  },
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage']

      return Config[sources[0]].call(this)
    },
    post() {
      if (!this.item.posts) return {}

      const post = this.item.posts.find(p => p.slug === this.slug)

      if (!post) {
        // redirect
        this.$router.push({ name: 'Project' })
      }

      return post
    },
    customComponents() {
      return { ...CustomComponents, ...PostComponents }
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
  },
  created() {
    this.fetch()
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.post-page {
  .plans {
    .kpb-gallery__item {
      border: 1px solid #{$dark-grey};
    }
  }
}
</style>
