<template>
  <div class="icons" v-if="data.icons" :style="style">
    <Card
      v-for="(item, index) in data.icons"
      :key="'card' + index"
      tag="div"
      :thumbnail="item.icon.url"
      class="icons__card"
    >
      <div v-html="item.content" class="icons__text"></div>
    </Card>
  </div>
</template>

<script>
import Card from '@kvass/card'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        icons: [],
      }),
    },
    columns: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    style() {
      return {
        '--kpb-icons-columns': this.columns,
      }
    },
  },
  components: {
    Card,
  },
}
</script>

<style lang="scss">
.icons {
  --kpb-section-width-override: 1200px;

  $space: 2.5rem;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(calc(calc(var(--kpb-section-width-override) - 8rem) / var(--kpb-icons-columns)), 1fr)
  );

  gap: 7rem $space !important;
  align-items: flex-start;

  @include respond-below('phone') {
    gap: 5rem !important;
  }

  .kvass-card {
    --kvass-card-spacing: 0px 0px;
    box-shadow: none;
  }

  &__card {
    border-bottom: 7px solid var(--primary);
    @include respond-below('phone') {
      width: 85%;
    }

    .kvass-card__header {
      margin-inline: 1rem;

      background-position: left;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: inherit;
      min-height: 0px;
      margin-bottom: 10px;
      // height: 70px;
      // width: 50px;
      max-width: 100px;
      max-height: 100px;
      aspect-ratio: 2.35/1;
      border-bottom: 0;
    }
    background-color: inherit;
  }
  &__text {
    line-height: 1.7;
    padding-inline: 1rem;
    p:has(strong) {
      font-size: 1.25rem;
    }
    margin-bottom: 20px;
    font-size: 1rem;

    white-space: nowrap;
    p {
      margin: 0;
    }
  }
}
</style>
